<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 14:49:23
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2021-08-23 22:19:07
-->
<template>
  <div class="bg">
    <div class="login-box">
      <div class="logo"></div>
      <div class="form">
        <div class="row row--user">
          <input type="number" placeholder="请输入手机号" v-model="mobile" />
        </div>
        <div class="row row--pwd">
          <input type="password" placeholder="请输入密码" v-model="password" />
        </div>
        <button class="login" @click="login">登录</button>
        <div class="flex-x center between right mini-btn">
          <div class="forget" @click="$router.push({ name: 'ReviewForget' })">
            忘记密码
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from "../../../utils/api/utils";
import { Toast } from "vant";
export default {
  data() {
    return {
      mobile: "",
      password: "",
    };
  },
  methods: {
    login() {
      login({
        mobile: this.mobile,
        password: this.password,
      })
        .then((res) => {
          window.localStorage.setItem("token", res.data.data.token);
          this.$router.push({
            name: "ReviewIndex",
          });
        })
        .catch((res) => {
          console.log(res)
          Toast(res.data.msg);
        });
    },
  },
};
</script>

<style src="@/assets/css/review/login.css" scoped>
</style>
<style src="@/assets/css/review/login_pc.css" scoped>
</style>